<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>Relatório de Pedidos por Produtos</h4></el-row
            >
          </el-col>
          <el-col :md="16">
            <el-row type="flex" justify="end" align="center" :gutter="8">
              <el-button
                type="primary"
                icon="el-icon-s-operation"
                size="medium"
                @click="openFilterOrdersModal()"
              ></el-button>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-empty
        v-if="!orders"
        description="Utilize os filtros para buscar os pedidos"
      ></el-empty>
      <div
        v-for="(orders, productCategory) in CompiledProductOrders"
        :key="productCategory"
        v-else
      >
        <h2 class="el-table__subheader_name">{{ productCategory }}</h2>
        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          :data="orders"
          style="width: 100%; z-index: 0"
        >
          <el-table-column label="produto" prop="name"></el-table-column>
          <el-table-column label="total vendido (R$)">
            <template #default="{ row: order }">
              {{ currencyFormatter.format(order.total_amount) }}
            </template>
          </el-table-column>
          <el-table-column
            label="total vendido (KG)"
            :formatter="
              ({ weight }) => weightFormatter.format(Number(weight)) + ' kg.'
            "
          >
          </el-table-column>
          <el-table-column prop="quantity" label="quantidade de vendas">
          </el-table-column>
          

          <el-table-column
            label="média por venda (R$)"
            :formatter="
              ({ avg_per_kg }) =>
                currencyFormatter.format(avg_per_kg)
            "
          ></el-table-column>
          <el-table-column
            label="média por venda (KG)"
            :formatter="
              ({ avg_per_order }) =>
                weightFormatter.format(avg_per_order) + ' kg.'
            "
          ></el-table-column>
        </el-table>
      </div>
    </el-card>
    <filter-orders-modal
      :showModal="showFilterOrdersModal"
      :filters="filterOptions"
      @close-modal="showFilterOrdersModal = false"
      @update-filters="updateFilters"
    ></filter-orders-modal>
  </div>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderService from "../services/orders";
import { generatePDF } from "../services/reports";
import {
  dateFormatter,
  currencyFormatter,
  weightFormatter,
  timeFormatter,
  dateFormatterShort,
  dateFormatterLong,
} from "../utils/formatters";
import FilterOrdersModal from "./modals/FilterOrdersModal.vue";

export default {
  name: "OrdersCompletedDailyPage",
  data: () => ({
    hasError: false,
    isLoading: false,
    orders: null,
    currencyFormatter,
    dateFormatter,
    showFilterOrdersModal: false,
    filterOptions: {
      status: ["completed"],
    },
    timeFormatter,
    dateFormatterShort,
    dateFormatterLong,
    weightFormatter
  }),
  components: { FilterOrdersModal },
  computed: {
    Orders() {
      return this.orders || [];
    },
    GroupedOrders() {
      return this.groupByProductId();
    },
    ProductOrders() {
      return this.Orders.map((order) => order.products).flat();
    },
    GroupedProductOrders() {
      return this.ProductOrders.reduce((reducer, productOrder) => {
        const key = productOrder.product?.category?.name;
        if (Array.isArray(reducer[key])) reducer[key].push(productOrder);
        else reducer[key] = [productOrder];
        return reducer;
      }, {});
    },
    CompiledProductOrders() {
      const compiledProducts = {};

      for (let groupedKey in this.GroupedProductOrders) {
        const groupedProducts = this.groupByProductId(
          this.GroupedProductOrders[groupedKey]
        );

        compiledProducts[groupedKey] = Object.values(groupedProducts).map(
          (groupedProduct) => {
            const compiledResults = this.compileResults(groupedProduct);

            compiledResults["avg_per_kg"] =
              compiledResults["total_amount"] / compiledResults["weight"];
            compiledResults["avg_per_order"] =
              compiledResults["weight"] / compiledResults["quantity"];

            return compiledResults;
          }
        );
      }

      return compiledProducts;
    },
  },
  methods: {
    openFilterOrdersModal() {
      this.showFilterOrdersModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters;
      this.fetchOrders(true);
    },
    groupByProductId(productOrders) {
      const grouped = productOrders?.reduce((t, o) => {
        const productId = o?.mounted?.id;
        if (t[productId]) t[productId].push(o);
        else t[productId] = [o];

        return t;
      }, {});

      return grouped;
    },
    compileResults(items) {
      return items?.reduce((reducer, item) => {
        if (!reducer["total_amount"]) reducer["total_amount"] = 0;

        reducer["total_amount"] += Number(item["total_amount"]);

        if (!reducer["quantity"]) reducer["quantity"] = 0;

        reducer["quantity"]++;

        if (!reducer["weight"]) reducer["weight"] = 0;

        reducer["weight"] += Number(item["weight"]);

        if (!reducer["amount"]) reducer["amount"] = 0;

        reducer["amount"] += Number(item["amount"]);

        reducer["name"] = `${item.product.name} ${item.mounted.cutting ?? ""} ${
          item.mounted.composition ?? ""
        }`;

        return reducer;
      }, {});
    },
    executeUserCommand(command) {
      switch (command) {
        case "OrderByCreatedAt":
          this.createPDF({ created_at: false }, "date");
          break;
        case "OrderByInvoiceId":
          this.createPDF({ invoice_id: true }, "number");
          break;
      }
    },
    convertOrderValuesByType(first, second, key, orderByType) {
      let f, s;
      switch (orderByType) {
        case "date":
          f = new Date(first[key]);
          s = new Date(second[key]);
          break;
        case "number":
          f = Number(first[key]);
          s = Number(second[key]);
          break;
        default:
          f = first[key];
          s = second[key];
      }

      return { f, s };
    },
    generatePDFContent(orderBy, orderByType) {
      const mappedOrders = Object.values(this.GroupedOrders).map((orders) => {
        const mappedOrders = orders
          .sort((first, second) => {
            const [[key, asc]] = Object.entries(orderBy);

            const { f, s } = this.convertOrderValuesByType(
              first,
              second,
              key,
              orderByType
            );

            if (asc) return f < s ? -1 : 1;
            else f > s ? -1 : 1;
          })
          .map(
            ({
              code,
              seller,
              client,
              created_at,
              invoice,
              total_amount,
              payments,
            }) => [
              code?.toString() || " ",
              `${this.dateFormatter.format(
                new Date(created_at)
              )}\n  ${this.timeFormatter.format(new Date(created_at))}`,
              client?.legal?.social_name || client?.name || " ",
              client?.legal?.company_name || " ",

              this.currencyFormatter.format(
                this.sumAmountPaymentOrders(payments)
              ),
              this.currencyFormatter.format(
                this.sumPaidOrderPayments(payments)
              ),
              this.currencyFormatter.format(
                Number(total_amount) - this.sumPaidOrderPayments(payments)
              ),
              invoice?.nfe || " ",
              payments
                ?.map(
                  ({ expires_at }) =>
                    (expires_at &&
                      this.dateFormatter.format(new Date(expires_at))) ||
                    "sem vencimento"
                )
                .join("\n"),
              seller?.name || " ",
            ]
          );

        mappedOrders.push([
          "Total",
          " ",
          " ",
          " ",
          this.currencyFormatter.format(
            orders.reduce(
              (t, { payments }) => t + this.sumAmountPaymentOrders(payments),
              0
            )
          ),
          this.currencyFormatter.format(
            orders.reduce(
              (t, { payments }) => t + this.sumPaidOrderPayments(payments),
              0
            )
          ),
          this.currencyFormatter.format(
            orders.reduce(
              (t, { payments, total_amount }) =>
                t + Number(total_amount) - this.sumPaidOrderPayments(payments),
              0
            )
          ),
        ]);

        return mappedOrders;
      });

      mappedOrders.push([
        [
          "Total de Pedidos",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          Object.values(this.GroupedOrders).flat().length,
        ],
        [
          "Valor Total",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.currencyFormatter.format(
            Object.values(this.GroupedOrders)
              .flat()
              .reduce((t, { total_amount }) => t + Number(total_amount), 0)
          ),
        ],
        [
          "Valor Pago",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.currencyFormatter.format(
            Object.values(this.GroupedOrders)
              .flat()
              .reduce(
                (t, { payments }) => t + this.sumPaidOrderPayments(payments),
                0
              )
          ),
        ],
      ]);

      return mappedOrders;
    },
    generateTableHeaders() {
      return [
        [
          "pedido",
          "data pedido",
          "cliente",
          "razão social",
          "valor",
          "pago",
          "restante",
          "nf",
          "vencimento",
          "vendedor",
        ],
      ];
    },
    createPDF(orderBy, orderByType) {
      const headers = this.generateTableHeaders();
      const content = this.generatePDFContent(orderBy, orderByType);

      const headerMessage = `Relatório de Pedidos - ${this.dateFormatter.format(
        this.day
      )}`;

      generatePDF({
        fileName: `Relatório de Pedidos - ${this.dateFormatter
          .format(this.day)
          .replace(/\//g, "-")}.pdf`,
        headerMessage,
        headers,
        content,
        isMultipleTables: true,
        addTableSummary: true,
        addTableTitle: true,
        defaultFontSize: 8,
      });
    },
    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production", "delayed"].includes(order.status)
      )
        order.status = "delayed";

      if (order.deleted_at) order.status = "canceled";
      return ORDER_STATUS_TYPES[order.status];
    },
    async fetchOrders(showLoading = false) {
      if (showLoading) this.isLoading = true;

      const { orders } = await OrderService().index({
        ...this.filterOptions,
        include_product_category: true,
        include_product_mounted: true,
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
    sumAmountPaymentOrders(payments) {
      return payments?.reduce((t, { amount }) => t + Number(amount), 0) || 0;
    },
    sumPaidOrderPayments(payments) {
      return (
        payments?.reduce(
          (t, { amount, paid_at }) => (paid_at ? t + Number(amount) : t),
          0
        ) || 0
      );
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  delayed: "danger",
  canceled: "danger",
  delivered: "success",
  completed: "info",
  finalized: "success",
};
</script>
<style scoped>
.el-card {
  width: 100%;
}

.mt-1 {
  margin-top: 5px;
}
</style>
